<template>
  <div class="logs">
    <h2>Отчет add-ins Outlook</h2>
    <ReportLogs :items="items" />
    <footer>
      <mts-date-picker
        v-model="fromDate"
        placeholder="дд.мм.гггг"
        prefix-icon="mts-icon-calendar"
        label="От:"
        style="width: 180px; margin-right: 16px"
        required
        clearable
      />
      <mts-date-picker
        v-model="toDate"
        placeholder="дд.мм.гггг"
        prefix-icon="mts-icon-calendar"
        label="До:"
        style="width: 180px; margin-right: 16px"
        required
        clearable
      />
      <button class="logs-excel-btn" @click.prevent="onClick">
        <Download />
        <span>Отчет в Excel</span>
      </button>
    </footer>
  </div>
</template>

<script>
import Download from '@/pic/svg/download.vue'
import ReportLogs from './comp/report-logs/report-logs.vue'
import { getDate, getTime } from './func'
import XLSX from 'xlsx'
export default {
  name: 'index-zoom-page',
  components: { ReportLogs, Download },
  props: {
    items: { type: Array }
  },
  data() {
    return {
      fromDate: new Date(),
      toDate: new Date()
    }
  },
  computed: {},
  methods: {
    onClick() {
      const _name = `лог ${getDate(Date.now())}.xlsx`

      const bodyUser = this.items.map((e) => [
        e.name,
        e.email,
        `${getDate(e.ctime * 1000)} ${getTime(e.ctime * 1000)}`,
        e.req,
        e.res,
        e.model
      ])

      const logTab = {
        name: 'пользователи',
        header: ['имя', 'почта', 'дата', 'вопрос', 'ответ', 'модель'],
        body: bodyUser
      }

      const arr = [logTab]
      const book = XLSX.utils.book_new()
      arr.forEach((item) => {
        const data = item.body
        data.unshift(item.header)
        const sheet = XLSX.utils.aoa_to_sheet(data)
        XLSX.utils.book_append_sheet(book, sheet, item.name)
      })
      XLSX.writeFile(book, _name)
    }
  },
  watch: {
    fromDate(e) {
      this.$emit('change-from-date', e.getTime())
    },
    toDate(e) {
      const endD = new Date(e.setHours(23, 59, 59, 999))
      this.$emit('change-to-date', endD.getTime())
    }
  },
  async created() {}
}
</script>

<style lang="scss">
@import './styles';
</style>
