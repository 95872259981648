<template>
  <Logs :items="sortLogs" @change-from-date="changeFromDate" @change-to-date="changeToDate" />
</template>

<script>
import Logs from './logs'
import { getAxiosUsersLog } from '@/views/logs/func'
export default {
  name: 'index-logs',
  components: { Logs },
  data() {
    return {
      logs: [],
      fromDate: new Date().setHours(0, 0, 0, 0),
      toDate: new Date().setHours(23, 59, 59, 999)
    }
  },
  methods: {
    changeFromDate(e) {
      this.fromDate = e
    },
    changeToDate(e) {
      this.toDate = e
    }
  },
  computed: {
    sortLogs() {
      return this.logs.filter((i) => {
        const t = i.ctime * 1000
        return  t >= this.fromDate && t <= this.toDate
      })
    }
  },
  async created() {
    this.logs = await getAxiosUsersLog()
  }
}
</script>
